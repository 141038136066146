@import '../../../scss/utilities';

.divider {
  // typically prefer not to style top margin/padding, if this becomes unwieldly let's remove and override as needed
  width: 100%;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  border: 0;
  border-bottom: $ue-dashed-border;
  &--padded {
    margin-top: $grid-gutter-width * 2;
    margin-bottom: $grid-gutter-width * 2;
  }
}
